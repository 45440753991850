import { AnimatePresence } from "framer-motion"
import React from "react"

export default function PageWrapper({ children }) {
  return (
    <>
      <AnimatePresence exitBeforeEnter initial={false}>
        {children}
      </AnimatePresence>
    </>
  )
}
