import * as React from "react"
import PageWrapper from "./src/components/PageWrapper"
import { PreviewStoreProvider } from "gatsby-source-prismic"
import "./src/tailwind/global.css"
import smoothscroll from "smoothscroll-polyfill"
export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
)
export const wrapPageElement = ({ element, props }) => {
  return <PageWrapper {...props}>{element}</PageWrapper>
}

export const onClientEntry = () => {
  smoothscroll.polyfill()
}
export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  return false
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  window.__location = location
  window.__prevLocation = prevLocation

  prevLocation &&
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }, 500)
}

/*
  HEADER GLICH

*/
